'use client';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
// import "./src/styles/index.scss"
import SiteWrapper from '@components/furniture/site/sitewrapper';
import '@styles/index.scss';
// import {AnimatePresence} from 'framer-motion';
import React from 'react';
import { QueryParamProvider } from 'use-query-params';

export const onClientEntry = () => {
  // console.log("We've started!");
  // callAnalyticsAPI()
};

export const onInitialClientRender = () => {
  // console.log("loaded");
  setTimeout(function () {
    if (document.getElementById('___loader') !== null)
      document.getElementById('___loader').style.display = 'none';
  }, 2000);
};

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <QueryParamProvider>
        <SiteWrapper>
          <div id="___loader" className="loader-container">
            <div className="loader"></div>
          </div>
          <div className="page-wrapper">{element}</div>
        </SiteWrapper>
      </QueryParamProvider>
    </>
  );
};

export const wrapPageElement = ({ element }) => {
  // <AnimatePresence mode="wait">
  return (
    <>
      <div className="thePage">{element}</div>
    </>
  );
  // </AnimatePresence>
};
