import { ICatsObject } from '@components/composite/1337-cats/interfaces/ICatsObject';
import ItemDetails, {
  IConfiguredItem,
} from '@components/composite/1337-cats/tshirt/item-details';
import { IBasketItem, IShopItem } from '@components/composite/shop/shop';
import { IBasketController } from '@components/controllers/form-controller';
import BasketController from '@components/controllers/shop/basket/basket-controller';
import { LocalStorageObjects } from '@enums';
import { Button, message, notification } from 'antd';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

const SiteWrapper: FunctionComponent<PropsWithChildren<any>> = ({
  children,
}: PropsWithChildren<any>) => {
  /* shop stuff */
  const [qsCatID, setQuerystringCatID] = useQueryParam('cat', StringParam);
  const [qsProductID, setQuerystringProductID] =
    useQueryParam<'photoshoot'>('product');
  const [catImages, setCatImages] = useState<ICatsObject[]>([]);
  const [loading, setIsLoading] = useState(false);
  const [loaded, setIsLoaded] = useState(false);
  const thing: 'photoshoot' = qsProductID ?? 'photoshoot';

  /* end shop stuff */
  const openRemovedItemNotification = (
    showUndo: boolean,
    removedMessage?: string,
  ) => {
    const key = `open${Date.now()}`;
    const btn = showUndo ? (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
        }}
      >
        Undo
      </Button>
    ) : (
      <></>
    );
    const undoButton = showUndo ? btn : null;
    notification.open({
      message: 'Item removed',
      description:
        removedMessage ?? 'We have removed this item from your basket!',
      btn: undoButton,
      key,
      onClose: close,
    });
  };

  var basketItemsInLocalstorage: IBasketItem[] = [];
  if (
    typeof window !== 'undefined' &&
    window.localStorage.getItem(LocalStorageObjects.BASKET_ITEMS) !== null
  )
    basketItemsInLocalstorage = JSON.parse(
      window.localStorage
        .getItem(LocalStorageObjects.BASKET_ITEMS)
        ?.toString() || '{}',
    );

  const [basketItemsNG, setBasketItemsNG] = useState<IBasketItem[]>(
    basketItemsInLocalstorage,
  );

  const key = 'updatable';

  const openMessage = () => {
    message.loading({ content: 'Adding to basket...', key });
    setTimeout(() => {
      message.success({ content: 'Added!', key, duration: 2 });
    }, 1000);
  };

  const clearAllBasket = () => {
    // ask confirm
    setBasketItemsNG([]);
    localStorage.setItem(LocalStorageObjects.BASKET_ITEMS, JSON.stringify([]));
    openRemovedItemNotification(false);
  };

  const removeItemFromBasket = (itemSKU: string) => {
    var nowItems = [];
    nowItems = basketItemsNG.filter((obj) => {
      return obj.itemConfiguration.sku !== itemSKU;
    });

    setBasketItemsNG(nowItems as IBasketItem[]);
    localStorage.setItem(
      LocalStorageObjects.BASKET_ITEMS,
      JSON.stringify(nowItems),
    );
    openRemovedItemNotification(false);
  };

  const goToCheckout = () => {};
  const addToBasket = (
    item: IShopItem,
    quantity: number,
    itemConfiguration?: IConfiguredItem,
  ) => {
    if (itemConfiguration !== undefined) {
      itemConfiguration!.sku = `${item.SKU}`;
    } else {
      itemConfiguration = { sku: 'photoshoot' };
    }

    const newBasketItem: IBasketItem = {
      basketItem: item,
      itemConfiguration: itemConfiguration as IConfiguredItem,
      quantity: quantity ?? 1,
      timeAdded: new Date().toISOString(),
      itemIsModified: false,
    };
    const basketsWithUpdatedTotals = [...basketItemsNG, newBasketItem];

    setBasketItemsNG(basketsWithUpdatedTotals);
    localStorage.setItem(
      LocalStorageObjects.BASKET_ITEMS,
      JSON.stringify(basketsWithUpdatedTotals),
    );

    openMessage();
  };
  // console.log(basketItemsNG.length);
  const basketControllerInstance: IBasketController = {
    clearAllItems: clearAllBasket,
    viewItem: (item: IShopItem) => {},
    removeItem: removeItemFromBasket,
    gotoCheckout: goToCheckout,
    addToBasket: addToBasket,
    basketItems: basketItemsNG,
  };
  return (
    <>
      <BasketController controller={basketControllerInstance}>
        {children}
      </BasketController>
    </>
  );
};

export default SiteWrapper;
