import { IBasketController } from '@controllers/form-controller';
import { createContext } from 'react';

export interface IBasketContext {
  controller: IBasketController;
}

const BasketContext = createContext<IBasketContext | undefined>(undefined);

export default BasketContext;
