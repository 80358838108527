import BasketContext from '@contexts/basket-context';
import { IBasketController } from '@controllers/form-controller';
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';

interface IBasketControllerProps {
  children?: ReactNode;
  endpoint?: string;
  isUpdating?: boolean;
  controller: IBasketController;
}

const BasketController: FunctionComponent<
  PropsWithChildren<IBasketControllerProps>
> = ({ children, controller }: IBasketControllerProps) => {
  const JSXPartialTemplate = (
    <>
      <BasketContext.Provider value={{ controller: controller }}>
        {children}
      </BasketContext.Provider>
    </>
  );

  return JSXPartialTemplate;
};

export default BasketController;
export type { IBasketControllerProps };
